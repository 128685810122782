<template>
  <b-modal
    id="modal-levelup-event-winner"
    ref="modal-levelup-event-winner"
    :hide-footer="true"
    modal-class="custom-modal1"
    @hidden="onClose"
    @shown="onShown"
  >
    <div class="canvas-container to-center-abs">
      <AnimationEventLevelup
        :animation-step="'winner'"
        :loop="true"
        :run="true"
        @done="animReady = true"
      ></AnimationEventLevelup>
    </div>
    <Spinner
      v-if="!animReady"
      size="medium"
      context="Loading Event..."
      classes="dead-center"
    ></Spinner>
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
    </template>

    <template v-if="animReady" slot="default">
      <div class="headline-container to-center-abs">
        <div class="headline1 golden-gradient-text text-uppercase">
          <template v-if="winners.length > 1">
            {{ $t("events.levelup.winners.headline_1a") }}</template
          >
          <template v-else-if="winners.length === 1">
            {{ $t("events.levelup.winners.headline_1a_singular") }}</template
          >
        </div>

        <div class="headline2 golden-gradient-text text-uppercase">
          <template v-if="winners.length > 1">
            {{ $t("events.levelup.winners.headline_1b") }}</template
          >

          <template v-else-if="winners.length === 1">
            {{ $t("events.levelup.winners.headline_1b_singular") }}</template
          >
        </div>
        <div class="headline3 golden-gradient-text text-uppercase">
          {{ voucherVal }}€ {{ $t("events.raffle.amazon.voucher.name>one") }}
        </div>
      </div>

      <div v-if="winners" class="winner-list to-center-abs">
        <div
          v-for="winner in winners"
          :key="winner.id"
          class="winner clickable"
          @click="fetchPublicUser(winner.user_id)"
        >
          {{ winner.user.name }}
        </div>
      </div>

      <div class="mail-info">
        {{ $t("events.levelup.winners.mailtext") }}
      </div>

      <div class="foot-note to-center-abs text-left">
        <div class="sub1">
          {{ $t("events.levelup.winners.raffle.disclaimer_1a") }}
        </div>
        <div class="sub2">
          {{ $t("events.levelup.winners.raffle.disclaimer_1b") }}
        </div>
      </div>
      <div v-if="userIsWinner" class="mail-info addon">
        <p>{{ $t("events.battle.winners.mailtext_addon") }}</p>
        <button class="ok-button-green" @click="handleSupportClick">
          {{ $t("support.contact.tab_title") }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Spinner from "@/components/animation/animation-spinner.vue";
import getPublicUserInfos from "@/mixins/getPublicUserInfos.js";
import eventPopups from "@/mixins/eventPopups";
import AnimationEventLevelup from "@/components/animation/animation-spine-event-levelup.vue";

export default {
  name: "ModalLevelupEventWinner",
  components: {
    AnimationEventLevelup,
    Spinner,
  },

  mixins: [getPublicUserInfos, eventPopups],

  data() {
    return {
      animReady: false,
    };
  },
  mounted() {
    this.$bvModal.show("modal-levelup-event-winner");
  },

  methods: {
    onClose() {
      this.$store.commit("popups/setNextPipelineQueueStep");
      this.animReady = false;
    },
    onShown() {
      this.$store.commit("events/setShowEventWinner", new Date());
    },
  },
};
</script>
<style lang="scss">
$modal-width: 1200px;
$modal-height: 715px;

#modal-levelup-event-winner {
  .canvas-container {
    left: -120px;
    top: -50px;
  }

  .modal-dialog {
    max-width: $modal-width;
    width: 100%;
    height: $modal-height;
    padding-top: 50px;
    left: 25px;

    @media (max-height: 770px), (max-width: 1250px) {
      transform: scale(0.9);
      top: -40px;
      margin-bottom: -10%;
    }
    @media (max-height: 690px), (max-width: 1100px) {
      transform: scale(0.8);
      top: -120px;
      margin-bottom: -20%;
    }

    @media (max-width: 1250px) {
      left: -100px;
    }
  }

  .modal-header {
    margin-top: 0;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    top: 0;
    right: 0;

    .close-button {
      right: 100px;
      top: 30px;
    }
  }

  .modal-body {
    margin-top: 0 !important;
    width: $modal-width;
    height: $modal-height;
    overflow: hidden;
  }

  .modal-content {
    width: 100%;
    height: 100%;
    background: none;
  }

  .golden-gradient-text {
    font-family: Ubuntu-Bold;
    font-size: 30px;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      #f7ffff,
      #ffee51 58%,
      #8b5a00
    );
    background-image: linear-gradient(to bottom, #f7ffff, #ffee51 58%, #8b5a00);
    filter: drop-shadow(0 0 15px rgba(0, 0, 0, 0.7)) drop-shadow(0 0 8px black);
    line-height: 1;

    &.big {
      font-size: 56px;
    }
  }

  .headline-container {
    left: 188px;
    top: 41px;

    .headline1 {
      font-size: 72px;
      font-family: Ubuntu-Bold;
      line-height: 1.5;
    }

    .headline2 {
      font-size: 24px;
      font-family: Ubuntu-Bold;
      line-height: 1.5;
    }

    .headline3 {
      font-size: 42px;
      font-family: Ubuntu-Bold;
    }
  }

  .winner-list {
    position: absolute;
    left: 170px;
    top: 250px;

    .winner {
      font-size: 40px;
      color: white;
      font-family: Ubuntu-Bold;
    }
  }

  .mail-info {
    position: absolute;
    left: 35px;
    top: 438px;
    width: 220px;
    line-height: 1.3;
    text-align: left;
    font-size: 28px;
    font-family: Ubuntu-Bold;
    text-shadow: 1px 1px 8px #4de5ff;
    &.addon {
      right: 35px;
      left: auto;
      top: 270px;
      font-size: 20px;
    }
  }

  .foot-note {
    font-size: 16px;
    font-family: Ubuntu;
    color: #4f4f4f;
    bottom: 10px;
    left: 32px;
    line-height: 1.3;
  }
}
</style>

<style scoped lang="scss">
$modal-width: 1200px;
$modal-height: 688px;
#modal-levelup-event-winner {
  .content {
    height: $modal-height;
    position: relative;
    margin-left: auto;
    margin-right: auto;

    &.modal-content {
      width: $modal-width;
    }
  }
}
</style>
